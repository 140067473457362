import React, { Component } from 'react'
import { getPortfolio } from '../contentful'
import Review from './Review'

class Testimonial extends Component{
    state = {
        portfolio: []
    }

    componentDidMount(){
        getPortfolio().then(data => this.setState({portfolio: data}))
    }


    render(){
        return(
            <div className="contents">
            <h3 style={{textAlign:'center'}}>Customer Reviews</h3>
            {this.state.portfolio.map(review => <Review review={review} />
                
                
                )}
           </div>
        )
    }
}

export default Testimonial;