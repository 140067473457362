import React from 'react'
import { FaTwitter, FaLinkedin, FaShoppingCart } from 'react-icons/fa';
import I18n from  '../i18n/i18n'


import './Toolbar.css'
import DrawerToggleButton from '../DrawerToggleButton/DrawerToggleButton'

const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar__navigation">
      <div className="toolbar__toggle-button">
          <DrawerToggleButton click={props.drawerClickHandler} />
          </div>
      <div className="toolbar__logo">
      </div>
      <div className="spacer" />
      <div className="toolbar_navigation-items">
  
        <ul>
          {/* <I18n/> */}
          <li>
            <a href="https://www.linkedin.com/in/adam-woolf-5b825344/"><FaLinkedin/></a>
          </li>
          <li>
            <a href="https://twitter.com/webspinner15"><FaTwitter/></a>
          </li>
          {/* <li>    <a id="shopping-cart" className="snipcart-checkout"><FaShoppingCart /></a>
          <span style={{color:'yellow', paddingLeft:'5px'}} className="snipcart-items-count"></span></li> */}

        </ul>

      </div>
    </nav>
  </header>
)

export default toolbar