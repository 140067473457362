import React, { useState, useEffect } from 'react'
import { getPortfolio } from '../contentful'
import PortfolioPiece from './PortfolioPiece'
import { Link } from 'react-router-dom'
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import {FaGlobe, FaCoins} from 'react-icons/fa'

const Portfolio  = () => {
 const [portfolio, setPortfolio] = useState([])
 const [selectedPortfolio, setSelectedPortfolio] = useState([])
 const [selected, setSelected] = useState(true)

  useEffect(() => {
    getPortfolio().then(data => {
        setPortfolio(data)
        setSelectedPortfolio(data.filter(p => p.fields.featuredProject))
    })
  }, [])

  const toggleDisplay = () => {
      if(selected){
          setSelectedPortfolio(portfolio)
          setSelected(false)
      } else {
          setSelectedPortfolio(portfolio.filter(p => p.fields.featuredProject))
          setSelected(true)
      }

  }

  const filterStores = () => {
      setSelected(true)
      setSelectedPortfolio(portfolio.filter(p => p.fields.eCommerce))
          
}

const filterMultilingual = () => {
    setSelected(true)
    setSelectedPortfolio(portfolio.filter(p => p.fields.multilingual))
}
    

let intro;
if(selected){ intro =  <div>   <h3>Selected Portfolio</h3>
    <p>Here is a selection of projects I have built for clients around the world, using the latest technologies in web development.</p>
</div> } else { intro = <div>
  <h3>Complete Portfolio</h3>
    <p>This is a complete list of projects I have built for clients around the world, using the latest technologies in web development.</p>
    </div>
  
   }
   
        return(
            <div className="contents">
                <div style={{textAlign:'center'}} >
{intro}
           <div style={{display:'flex', justifyContent:'space-evenly'}} >
               <button onClick={toggleDisplay}  className="btn btn-info btn-sm" >{selected ? 'View Full Client Portfolio' : 'View Selected Projects'}</button>
               <button onClick={filterStores}  className="btn btn-info btn-sm" >Show eCommerce Sites <FaCoins/> </button>
               <button onClick={filterMultilingual}  className="btn btn-info btn-sm" >Show Multilingual Sites <FaGlobe/> </button>
               <Link className="btn btn-dark btn-sm" to="/reviews">Read the Customer Reviews</Link>

           </div>
           
            </div>
            <hr/>
            {selectedPortfolio.map(piece => 
            <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut" >
            <PortfolioPiece work={piece} />
            </ScrollAnimation>)}
            </div>
        )
  
}

export default Portfolio;