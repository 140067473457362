import React from 'react'
import marked from 'marked'
import {
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    FacebookIcon,
    FacebookShareButton,
  } from "react-share";
  import { Link } from "react-router-dom";

  const listFooter = {
    display: 'flex',
    justifyContent: 'space-between'
  }

const SinglePostDisplay = ({post}) => {
    const content = marked(post.fields.body)
    return(
        <div className="contents">
        <h2>{post.fields.title}</h2>
    <p><em>{post.fields.description}</em></p>
         <p>{post.fields.author.fields.name}</p>
       {post.fields.heroImage ? (
       <img style={{width:'200px', height:'150px'}} src={post.fields.heroImage.fields.file.url} />
     ) : null}
    
   
     <div dangerouslySetInnerHTML={{__html: content}} />

<div style={listFooter}>
     <Link to="/posts/">back</Link>
<div>
     <small>share on social media: </small>

     <TwitterShareButton
       url={window.location}
       children={<TwitterIcon size={24} />}
     />
     <LinkedinShareButton
       url={window.location}
       children={<LinkedinIcon size={24} />}
     />
     <FacebookShareButton
       url={window.location}
       children={<FacebookIcon size={24} />}
     />
</div>
</div>
     <div>
     </div>
   </div>
    )
}


export default SinglePostDisplay;