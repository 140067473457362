import React, { Component } from 'react';
import Main from './components/Main';
import Contact from './components/Contact';
import Gallery from './components/Gallery';
import WebDev from './components/WebDev';
import Posts from './components/Posts'
import Footer from './components/Footer'
import Musician from './components/Musician'
import Media from './components/Media'
import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import SinglePost from './components/SinglePost'
import Portfolio from './components/Portfolio'
import Services from './components/Services'
import Why from './components/Why'
import Testimonials from './components/Testimonials'


import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';


class App extends Component {
  state ={
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false})
  }

  render(){
    let backdrop;

    if(this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backdropClickHandler}/>
    }
  return (
    <Router>
  <div style={{height: '100%'}}>
      <Toolbar  drawerClickHandler={this.drawerToggleClickHandler} />
      <SideDrawer show={this.state.sideDrawerOpen} click={this.drawerToggleClickHandler}/>
      {backdrop}

      <main style={{marginTop:'56px'}}>
      </main>    <Switch>
    <Route exact path="/" component={Main}/>
    <Route path="/contact" component={Contact}/>
    <Route path="/about" component={WebDev}/>
    <Route path="/portfolio" component={Portfolio}/>
    <Route path="/services" component={Services}/>
    <Route path="/posts/:id" component={SinglePost}/>
    <Route path="/posts" component={Posts}/>
    <Route path="/why" component={Why}/>
    <Route path="/reviews" component={Testimonials}/>

    </Switch>
    <Footer/>

    </div>
    </Router>
  );
}
}

export default App;
