import React, { useState, useEffect } from "react";
import logo from "../../src/ws_logo.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

import { motion } from "framer-motion";

import TestimonialsSlider from "./TestimonialsSlider";

const Hero = (props) => {
  const [offsetY, setOffsetY] = useState(0);

  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <div
      className='hero'
      style={{ transform: `translateY(${offsetY * 0.5}px)`, zIndex: -1 }}
    >
      <img className='spin drawerlogo' src={logo} alt='' />
      <h1 className='header'>webSpinner</h1>
      <TestimonialsSlider />
      <motion.div
        style={{ textAlign: "center" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1 }}
      >
        <span>by</span>
        <h3 className='subtitle'>Adam Woolf</h3>
      </motion.div>
    </div>
  );
};

export default Hero;
