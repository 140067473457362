import React, { Component } from 'react'

class Services extends Component {
    render(){
        return(
            <h3>Services</h3>
        )
    }
}

export default Services;