import React from 'react'
import { Row, Col } from 'react-bootstrap'
import marked from 'marked'


const PortfolioPiece = ({work}) => {

    let features
    
    { work.fields.features ? features = marked(work.fields.features) : features = null}




   return (
    <div className="portfolio-piece" >
    <Row>
        <Col md={8}>
        <h4>{work.fields.title}</h4>
<p>{work.fields.subtitle}</p>
<section dangerouslySetInnerHTML={{__html: features}} />

<div>

{ work.fields.techlogo ? <img src={work.fields.techlogo.fields.file.url} style={{height:45}} /> : null}
{work.fields.otherLogos && work.fields.otherLogos.map(logo => <img src={logo.fields.file.url} style={{height:35, marginLeft:20}} />)}
</div>
{work.fields.url && <a className="btn btn-dark btn-sm" style={{marginTop:25}} href={work.fields.url} target="_blank">Visit Project</a>}

</Col>
        <Col md={4}>
        <img style={{width:'100%'}} src={work.fields.image.fields.file.url} alt=""/></Col>

</Row>
</div>


)
    }

export default PortfolioPiece;