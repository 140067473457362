import React from "react";
import { FaTwitter, FaLinkedin, FaRegEnvelopeOpen } from "react-icons/fa";

const Contact = () => {
  return (
    <>
      <div className='contents'>
        <div className='contact'>
          <h4> Contact Me</h4>
          <h6>
            Whether it's about a new website, to refresh of an existing one, or
            any other web development project, I'd be happy to hear from you!
          </h6>
          <h6>
            Drop me a line at{" "}
            <a href='mailto:adam@webspinner.eu'>adam@webspinner.eu</a> or find
            me via the social networks
          </h6>
          <hr />

          <div className='social'>
            <a href='https://www.linkedin.com/in/adam-woolf-5b825344/'>
              <FaLinkedin size={30} />
            </a>

            <a href='https://twitter.com/webspinner15'>
              <FaTwitter size={30} />
            </a>

            <a href='mailto:adam@webspinner.eu'>
              <FaRegEnvelopeOpen size={30} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
