import React, {Component} from 'react'
import '../styles/stylesheet.css'
import Hero from './Hero'
import Biog from './Biog'
import Features from './Features'
import Translator from './i18n/Translator'
import Portfolio from './Portfolio'

class Main extends Component {
    render(){
        return <div  > 
            <Hero/>
            <div className='contents' style={{backgroundColor: 'whitesmoke'}}>
                <p>Whether you are looking for a brand new website for your business or brand, or a refresh of an existing on, you can count on WebSpinner to have the solution. All of our sites are built using a range of up to date technologies, depending on the requirements of each project and can incorporate a wide range of features. Needless to say, all sites are responsive and look good on any device.

See the customer reviews in the Portfolio section below, or contact me to make an enquiry.</p>

            {/* <Biog/> */}

            <Portfolio/>
            </div>
    
        </div>
            
    }

}

export default Main
