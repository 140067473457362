import React from "react";
import marked from "marked";



const Review = (props) => {
  let review;
  if (props.review.fields.short) {
    review = (
      <div className="review">
        <p> {props.review.fields.title}</p>
        <p>
          <em>{props.review.fields.short}</em>
        </p>
      </div>
    );
  } else {
    review = null;
  }

  return <div>{review}</div>;
};

export default Review;
