import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const SlideItem = ({ item }) => (
  <motion.div
    initial={{ opacity: 0, y: 30 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -30 }}
    transition={{ duration: 0.8 }}
    style={{
      textAlign: "center",
      padding: 40,
      width: 700,
      maxWidth: "100%",
      margin: "auto",
    }}
  >
    <h3 style={{ fontWeight: 300 }}>{item}</h3>
  </motion.div>
);

const tests = ["Creative Web Design", "Custom Web Development"];

const TimerSlideMs = 4000;

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    clearInterval(timerSlider.current);
    timerSlider.current = setInterval(
      () =>
        setCurrentIndex((currentIndex) => (currentIndex + 1) % tests.length),
      TimerSlideMs
    );

    return () => {
      clearInterval(timerSlider.current);
    };
  }, []);

  const timerSlider = useRef(null);

  return (
    <div style={{ textAlign: "center", height: 100, maxWidth: "100%" }}>
      <div style={{ maxWidth: "100%" }}>
        {tests.map(
          (item, itemIndex) =>
            currentIndex === itemIndex && (
              <SlideItem key={itemIndex} item={item} />
            )
        )}
      </div>
    </div>
  );
};

export default Testimonials;
