import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedin, FaRegEnvelopeOpen } from "react-icons/fa";
import { Dropdown, NavLink, NavItem } from "react-bootstrap";
import logo from "../../ws_logo.png";

import "./SideDrawer.css";

const sideDrawer = (props) => {
  let drawerClasses = "side-drawer";
  if (props.show) {
    drawerClasses = "side-drawer open";
  }
  return (
    <div>
      <nav className={drawerClasses}>
        <div className='drawer-header'>
          <img
            className='spin drawerlogo'
            style={{ marginBottom: "20px" }}
            src={logo}
            alt=''
          />
          <h4>WEBSPINNER</h4>
          <h5>Adam Woolf - Web Developer</h5>

          <hr></hr>
        </div>
        <ul>
          <li>
            <Link onClick={props.click} to='/'>
              Home
            </Link>
          </li>
          <li>
            <Link onClick={props.click} to='/portfolio'>
              Portfolio
            </Link>
          </li>

          <li>
            <Link onClick={props.click} to='/about'>
              About Me
            </Link>
          </li>
          {/* <li>
                <Link onClick={props.click} to="/services">
                  Content Creation Service
                </Link>
                </li> */}
          <li>
            <Link onClick={props.click} to='/reviews'>
              Customer Reviews{" "}
            </Link>
          </li>

          <li>
            <Link onClick={props.click} to='/posts'>
              Blog
            </Link>
          </li>
          <li>
            <Link onClick={props.click} to='/contact'>
              Contact
            </Link>
          </li>
        </ul>

        <div className='social'>
          <a href='https://www.linkedin.com/in/adam-woolf-5b825344/'>
            <FaLinkedin size={30} />
          </a>

          <a href='https://twitter.com/webspinner15'>
            <FaTwitter size={30} />
          </a>

          <a href='mailto:adam@webspinner.eu'>
            <FaRegEnvelopeOpen size={30} />
          </a>
        </div>
      </nav>
    </div>
  );
};

export default sideDrawer;
