import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const WebDev = () => {
  return (
    <div className='contents webdev'>
      <h4> Web Development</h4>
      <Row>
        <Col md={9}>
          <p>
            I am a freelance web developer based in Europe with a client base
            all over the world. Currently dividing my time between working with
            New York based design and development studio,{" "}
            <a href='https://torii.studio' target='_blank'>
              Torii Studio
            </a>
            , and a range of client projects of my own in Europe and the US, my
            career is spent developing an exciting range of apps and websites! I
            specialise in building front ends (websites, web apps & platforms)
            using React - a Javascript library, and am specialised in
            integrating Material UI design system, among others. I have also
            worked with Angular and Vue.{" "}
          </p>
          <p>
            Many of my projects include a custom built content management system
            for easy client-management and as well as working with Shopify and
            Firebase APIs for online stores, member-login sites, databases and
            more. I also frequently develop projects using i18n localisation to
            create multi-lingual websites. In 2019 I started{" "}
            <a href='https://www.webspinner.eu'>WebSpinner.eu</a>.
          </p>

          <p>
            If you'd like to collaborate, or have enquiries about websites,
            please <a href='mailto:mail@adamwoolf.com'>drop me a line.</a>
          </p>
          <Link to='/portfolio'>Check out my Portfolio</Link>
        </Col>
        <Col md={3} className='logos'>
          <img className='logo' src={"/imgs/logoReact.jpeg"} />
          <img className='logo' src={"/imgs/logoAngular.png"} />
          <img className='logo' src={"/imgs/logoJS.png"} />
          <img className='logo' src={"/imgs/logoHTML.png"} />
          <img className='logo' src={"/imgs/logoCSS.png"} />
        </Col>
      </Row>
    </div>
  );
};

export default WebDev;
