const client = require('contentful').createClient({
    space: 'a9ly9rah62ns',
    accessToken: 'hO5sRsMG9k4UpzhQhX44CTG2-mE39O2ZolIvEzgBMJY'
  })
  
  const getBlogPosts = () => client.getEntries({content_type: 'blogPost'}).then(response => response.items)
  const getPortfolio = () => client.getEntries({content_type: 'testimonial', order: 'fields.order'}).then(response => response.items)
  const getPortfolioPiece = (slug) => client.getEntries({'field.title': slug, content_type: 'testimonial'}).then(response => response.items)
  

  const getSinglePost = slug =>
    client
      .getEntries({
        'fields.slug': slug,
        content_type: 'blogPost'
      })
      .then(response => response.items)
  
  export { getBlogPosts, getSinglePost, getPortfolio, getPortfolioPiece }